import { Routes } from '@angular/router';
import { AllowedView } from '@volt/shared/allowed-view';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';
import { NavPath } from '../models/navpath';
import { SortPriorityEnum } from '../models/SortPriorityEnum';
import { AppList } from '@volt/shared/guards/app-list.guard';
import { BannerFeature } from '@volt/shared/guards/banner.guard';


export const navPaths: NavPath[] = [
  {
    title: 'Dashboard',
    titleKey: translationKey('dashboard'),
    route: ['/dashboard'],
    visible: true,
    feature: 'dashboard',
    iconClass: 'fa fa-chart-area',
    permission: [PermissionNames.DashboardManage, Privilege.Read],
    sortOrder: SortPriorityEnum.Highest,
  },
  {
    title: 'Demo/Retailtainment',
    titleKey: translationKey('demoRetailtainment'),
    route: ['/demo/demo-dashboard'],
    visible: true,
    feature: 'dashboard',
    iconClass: 'fa fa-chart-area',
    permission: [PermissionNames.DemoRetailtainmentManage, Privilege.Read],
    sortOrder: SortPriorityEnum.Highest,
  },
  {
    title: 'Admin Dashboard',
    titleKey: translationKey('adminDashboard'),
    route: ['/dashboard/admin'],
    visible: true,
    iconClass: 'fa fa-chart-area',
    feature: 'dashboard',
    permission: [PermissionNames.AdminDashboardManage, Privilege.Read],
    sortOrder: SortPriorityEnum.Highest,
  },
  {
    title: 'Reports',
    titleKey: translationKey('reports'),
    visible: true,
    feature: 'reports',
    iconClass: 'fa fa-chart-bar',
    route: ['/reports'],
    permission: [PermissionNames.ReportsManage, Privilege.Read],
  },
  {
    title: 'Locations',
    titleKey: translationKey('locations'),
    iconClass: 'fa fa-map-marker',
    route: ['/locations'],
    feature: 'locations',
    permission: [PermissionNames.LocationsManage, Privilege.Read],
    children: [
      {
        title: 'Store Closures',
        titleKey: translationKey('storeClosures'),
        route: ['/locations/store-closures'],
        visible: true,
        iconClass: 'fa fa-store-slash',
        feature: 'emergencies',
        permission: [PermissionNames.LocationsManageClosures, Privilege.Read],
      },
    ],
  },
  {
    title: 'Users',
    titleKey: translationKey('users'),
    iconClass: 'fa fa-users',
    route: ['/users'],
    visible: false,
    feature: 'users',
    roleViews: [
      AllowedView.Admin,
      AllowedView.RetailerAdmin,
      AllowedView.RetailerOperationalManager,
      AllowedView.Provider,
    ],
  },
  {
    title: 'Emergencies',
    titleKey: translationKey('emergencies'),
    route: ['/emergencies/overview'],
    iconClass: 'fa fa-exclamation-triangle',
    visible: true,
    feature: 'emergencies',
    permission: [PermissionNames.EmergenciesManage, Privilege.Read],
    children: [
      {
        title: 'Emergency Management',
        titleKey: translationKey('emergencyManagement'),
        route: ['/emergencies/management'],
        iconClass: 'fa fa-exclamation-triangle',
        visible: true,
        permission: [PermissionNames.EmergenciesManage, Privilege.Read],
        feature: 'emergencies',
      },
      {
        title: 'Product Management',
        titleKey: translationKey('productManagement'),
        route: ['/emergencies/products/management'],
        iconClass: 'fa fa-cubes',
        visible: true,
        permission: [PermissionNames.EmergenciesManageProducts, Privilege.Read],
        feature: 'emergencies',
      },
    ],
  },
  {
    title: 'Service Deployments',
    titleKey: translationKey('serviceDeployments'),
    iconClass: 'fa fa-file-chart-line',
    visible: true,
    feature: 'servicedeployments',
    children: [
      {
        title: 'Event Review',
        titleKey: translationKey('eventReview'),
        iconClass: 'fa fa-tasks',
        visible: true,
        permission: [PermissionNames.SchedulesEventReview, Privilege.Read],
        route: ['/servicedeployments/event-review'],
      },
      {
        title: 'Service Deployments',
        titleKey: translationKey('serviceDeployments'),
        permission: [PermissionNames.SchedulesManage, Privilege.Read],
        feature: 'servicedeployments',
        iconClass: 'fa fa-file-chart-line',
        visible: true,
        route: ['/servicedeployments/data'],
      },
      {
        title: 'Service Logs',
        titleKey: translationKey('serviceLogs'),
        feature: 'servicelogs',
        iconClass: 'fa fa-list',
        visible: true,
        route: ['/servicedeployments/action-log/logs'],
        permission: [PermissionNames.SchedulesActionLog, Privilege.Read],
      },
    ],
  },
  {
    title: 'Field Notifications',
    titleKey: translationKey('fieldNotifications'),
    iconClass: 'fa fa-bell',
    route: ['/field-notifications'],
    visible: true,
    feature: 'Notifications',
    permission: [PermissionNames.FieldNotificationsManage, Privilege.Read],
  },
  {
    title: 'PFS Inventory',
    titleKey: translationKey('pfsInventory'),
    iconClass: 'fa fa-bell',
    route: ['/pfs-inventory'],
    visible: true,
    permission: [PermissionNames.PfsInventoryManage, Privilege.Read],
    appId: AppList.PFSUpload,
  },
  {
    title: 'Activities',
    titleKey: translationKey('activities'),
    iconClass: 'fa fa-chart-pie',
    route: ['/activities/data'],
    visible: true,
    feature: 'activities',
    permission: [PermissionNames.ActivitiesManage, Privilege.Read],
  },
  {
    title: 'Feature Troubleshooting',
    titleKey: translationKey('featureTroubleshooting'),
    iconClass: 'fa fa-tools',
    route: ['/feature-troubleshooting'],
    visible: true,
    feature: 'feature-troubleshooting',
    permission: [PermissionNames.FeatureAvailabilityManage, Privilege.Read],
  },
  {
    title: 'Suppliers',
    titleKey: translationKey('suppliers'),
    iconClass: 'fa fa-code-merge',
    route: ['/suppliers'],
    visible: true,
    feature: 'suppliers',
    permission: [PermissionNames.SuppliersManage, Privilege.Read],
  },
  {
    title: 'Departments',
    titleKey: translationKey('departments'),
    iconClass: 'fa fa-university',
    route: ['/departments'],
    visible: true,
    feature: 'departments',
    permission: [PermissionNames.DepartmentsManage, Privilege.Read],
  },
  {
    title: 'Manage Users',
    titleKey: translationKey('manageUsers'),
    iconClass: 'fa fa-users',
    route: ['/users'],
    visible: true,
    feature: 'dashboard',
    permission: [PermissionNames.UsersManage, Privilege.Read],
  },
  {
    title: 'Invoices',
    titleKey: translationKey('invoices'),
    iconClass: 'fa fa-file',
    route: ['/invoice-list'],
    visible: true,
    feature: 'dashboard',
    permission: [PermissionNames.InvoicesManage, Privilege.Read],
  },
  {
    title: 'Licenses',
    titleKey: translationKey('licenses'),
    iconClass: 'fa fa-id-card',
    route: ['/licenses'],
    visible: true,
    feature: 'dashboard',
    permission: [PermissionNames.InvoicesManage, Privilege.Read],
  },
  {
    title: 'Exceptions / Reports',
    titleKey: translationKey('exceptionsReports'),
    iconClass: 'fa fa-file-chart-line',
    route: ['/legacy-dashboard'],
    queryParams: { path: '/report/exceptionreport' },
    visible: true,
    feature: 'dashboard',
    permission: [PermissionNames.ExceptionsReportsManage, Privilege.Read],
  },
  {
    title: 'Assembler Priority',
    titleKey: translationKey('assemblerPriority'),
    iconClass: 'fa fa-bicycle',
    visible: true,
    feature: 'upload-assembler',
    permission: [PermissionNames.AssemblerPriorityManage, Privilege.Read],
    children: [
      {
        title: 'Upload Assembler',
        titleKey: translationKey('uploadAssembler'),
        route: ['/assembler-priority/management'],
        iconClass: 'fa fa-bicycle',
        visible: true,
        feature: 'upload-assembler',
        permission: [PermissionNames.AssemblerPriorityManage, Privilege.Read],
      },
    ],
  },
  {
    title: 'Vendor Log',
    titleKey: translationKey('vendorLog'),
    iconClass: 'fa fa-file-chart-line',
    route: ['/vendorlog'],
    visible: true,
    feature: 'vendorlog',
    permission: [PermissionNames.VendorLogManage, Privilege.Read],
  },
  {
    title: 'Supplier Registration',
    titleKey: translationKey('supplierRegistration'),
    iconClass: 'fa fa-university',
    route: ['/retaillink-registration'],
    visible: true,
    feature: 'registration',
    permission: [PermissionNames.SupplierRegistrationManage, Privilege.Read],
  },
  {
    title: 'Accounts',
    titleKey: translationKey('accounts'),
    visible: true,
    iconClass: 'fa fa-university',
    feature: 'accountlist',
    permission: [PermissionNames.AccountsManage, Privilege.Read],
    children: [
      {
        title: 'Legacy',
        titleKey: 'legacy',
        visible: true,
        iconClass: 'fa fa-university',
        route: ['/registration/account-list'],
        permission: [PermissionNames.AccountsManage, Privilege.Read],
      },
      {
        title: 'Beta',
        titleKey: 'beta',
        visible: true,
        iconClass: 'fa fa-university',
        route: ['/accounts/view'],
        permission: [PermissionNames.AccountsManage, Privilege.Read],
      },
      {
        title: 'Invite',
        titleKey: 'invite',
        visible: true,
        iconClass: 'fa fa-layer-plus',
        route: ['/accounts/invite'],
        permission: [PermissionNames.AccountsManage, Privilege.Read],
        feature: 'AccountInvite',
      },
    ],
  },
  {
    title: 'Badges',
    titleKey: translationKey('badges'),
    visible: true,
    iconClass: 'fa fa-thumbs-up',
    route: ['/badges'],
    feature: 'badges',
    permission: [PermissionNames.BadgesManage, Privilege.Read],
  },
  {
    title: 'ToDo List',
    titleKey: translationKey('todoList'),
    visible: true,
    iconClass: 'fa fa-th-list',
    route: ['/todo-list'],
    feature: 'todoList',
    permission: [PermissionNames.TodoListManage, Privilege.Read],
  },
  {
    title: 'Badge Reprint',
    titleKey: translationKey('badgeReprint'),
    visible: true,
    iconClass: 'fa fa-print',
    route: ['/badge-reprint'],
    feature: 'badge-reprint',
    permission: [PermissionNames.BadgeReprintManage, Privilege.Read],
  },
  {
    title: 'Activity Responsibility',
    titleKey: translationKey('activityResponsibility'),
    visible: true,
    iconClass: 'fa fa-exclamation-triangle',
    route: ['/activity-responsibility'],
    feature: 'activity-responsibility',
    permission: [PermissionNames.ActivityResponsibilityManage, Privilege.Read],
  },
  {
    title: 'FAQ',
    titleKey: translationKey('faq'),
    iconClass: 'fa fa-question-circle',
    route: ['/faq-data'],
    visible: true,
    feature: 'faq',
    permission: [PermissionNames.FaqManage, Privilege.Read],
  },
  {
    title: 'Support',
    titleKey: translationKey('support'),
    iconClass: 'fa fa-question-circle',
    route: ['/support'],
    visible: true,
    feature: 'support',
    permission: [PermissionNames.SupportManage, Privilege.Read],
  },
  {
    title: 'Roles',
    titleKey: translationKey('roles'),
    iconClass: 'fa fa-user-tag',
    route: ['/roles'],
    visible: true,
    feature: 'roles',
    permission: [PermissionNames.RolesManage, Privilege.Read],
    children: [
      {
        title: 'Retailer Role Mapping',
        titleKey: translationKey('retailerRoleMappingTitle'),
        iconClass: 'fa fa-user',
        route: ['/retailerRoleMapping'],
        visible: true,
        feature: 'roles',
        permission: [PermissionNames.RolesManage, Privilege.Read],
      },
      {
        title: 'Retailer Tag Mapping',
        titleKey: translationKey('retailerTagMapping'),
        iconClass: 'fa fa-tags',
        route: ['/retailerTagMapping'],
        visible: true,
        feature: 'roles',
        permission: [PermissionNames.RolesManage, Privilege.Read],
      },
    ],
  },
  {
    title: 'Supplier Signage',
    titleKey: translationKey('supplierSignage'),
    iconClass: 'fa fa-ad',
    visible: true,
    permission: [PermissionNames.SupplierSignageManage, Privilege.Read],
    bannerFeature: BannerFeature.supplierSignage,
    children: [
      {
        title: 'Supplier Signage Request',
        titleKey: translationKey('supplierSignageRequest'),
        iconClass: 'fa fa-file-invoice',
        route: ['/marketing-signage/request'],
        visible: true,
      },
      {
        title: 'Supplier Signage Dashboard',
        titleKey: translationKey('marketingSignageDashboard'),
        iconClass: 'fa fa-chalkboard',
        route: ['/marketing-signage/dashboard'],
        visible: true,
        permission: [PermissionNames.SupplierSignageDashboard, Privilege.Read],
      },
    ],
  },
  {
    title: 'Certifications',
    titleKey: translationKey('certifications'),
    iconClass: 'fa fa-graduation-cap',
    route: ['/certifications'],
    visible: true,
    permission: [PermissionNames.CertificationsManage, Privilege.Read],
  },
  {
    title: 'Tags',
    titleKey: translationKey('tags'),
    iconClass: 'fas fa-tags',
    route: ['/tags'],
    feature: 'tags',
    visible: true,
    permission: [PermissionNames.TagsManagePage, Privilege.Read],
  },
  {
    title: 'Allow Features',
    titleKey: translationKey('allowFeatures'),
    iconClass: 'fas fa-bezier-curve',
    route: ['/location-features'],
    visible: true,
    permission: [PermissionNames.LocationsManageLegacyFeatures, Privilege.Read],
  },
  {
    title: 'Location Grouping',
    titleKey: translationKey('locationGrouping'),
    iconClass: 'fas fa-object-group',
    route: ['/location-grouping'],
    visible: true,
    permission: [PermissionNames.LocationsManageGrouping, Privilege.Read],
  },
  {
    title: 'Communications',
    titleKey: translationKey('communications'),
    iconClass: 'fa fas fa-mail-bulk',
    visible: true,
    feature: 'communications',
    permission: [PermissionNames.MassCommunicationsManage, Privilege.Read],
    children: [
      {
        title: 'Send',
        titleKey: translationKey('send'),
        route: ['/communications/send'],
        iconClass: 'fa fas fa-paper-plane',
        visible: true,
        feature: 'communications',
        permission: [PermissionNames.MassCommunicationsSend, Privilege.Read],
      },
      {
        title: 'History',
        titleKey: translationKey('history'),
        route: ['/communications/history'],
        iconClass: 'fa fas fa-history',
        visible: true,
        feature: 'communications',
        permission: [PermissionNames.MassCommunicationsHistory, Privilege.Read],
      },
      {
        title: 'Templates',
        titleKey: translationKey('templates'),
        route: ['/communications/templates'],
        iconClass: 'fa fas fa-edit',
        visible: true,
        feature: 'communications',
        permission: [PermissionNames.MassCommunicationsTemplates, Privilege.Read],
      },
    ],
  },
  {
    title: 'Field Group Types',
    titleKey: translationKey('fieldGroupTypes'),
    route: ['/field-group-type'],
    iconClass: 'fa fa-edit',
    visible: true,
    permission: [PermissionNames.ReportsFieldGroupTypeSummary, Privilege.Read],
  },
  {
    title: 'Diagnostics',
    titleKey: translationKey('diagnostics'),
    iconClass: 'fas fa-ball-pile',
    visible: true,
    permission: [PermissionNames.DiagnosticsManage, Privilege.Read],
    children: [
      {
        title: 'Status',
        titleKey: translationKey('status'),
        route: ['/diagnostics/status'],
        iconClass: 'fas fa-box-open',
        visible: true,
        permission: [PermissionNames.DiagnosticsManage, Privilege.Read],
      },
    ],
  },
];
